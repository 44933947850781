window.onload = function(){

  const onScroll = () => {
    const navbar = document.querySelector('.navbar-outer');
    if(window.scrollY > 100){
      navbar.classList.add('navbar-outer--fixed')
    }else{
      navbar.classList.remove('navbar-outer--fixed')
    }
  }

  
  document.onscroll = onScroll;

  const formValidate = () => {
    document.querySelector('#license').onchange = (e) => {
      document.querySelector('#licenseH').checked = e.target.checked;
    }

    const onSubmit = (e) => {

      const growlsWrapper = document.getElementById('growls-default');
      const growlsLength = (!!growlsWrapper && !growlsWrapper.children.length) || !growlsWrapper;

      let data = {
        name:document.getElementById('name').value, 
        email:document.getElementById('email').value,
        phone:document.getElementById('phone').value,
        msg:document.getElementById('msg').value
      };
      let response = fetch('/mail.php', { method: 'POST', body: JSON.stringify(data)});

      if(growlsLength){
        if(!!growlsWrapper){
          growlsWrapper.style.display = 'block';
        }
        
        $.growl.notice({
          message: "Спасибо! Мы скоро свяжемся с вами.",
          duration: 2000, 
          style: 'custom', 
          size: 'custom',
          title: `<img src="/img/icons/checkmark-circle-fill.svg" />`
        });
        setTimeout(()=>{
          document.getElementById('growls-default').style.display = 'none';
        },2000);
      }

      return false;
    }
    $('.section-form__form').validate({
      submitHandler: () => {
        onSubmit();
      },
      rules: {
				name: "required",
				email: "required",
				phone: "required",
				licenseH: "required",
			},
      messages: {
        name: "Укажите ваше имя",
        email: "Укажите ваш e-mail",
        licenseH: 'Необходимо принять согласие на обработку персональных данных',
        phone: 'Укажите ваш телефон'
      }
  });
  }

  const licenseCheckListener = () =>{
    const licenseCheckbox = document.getElementById('license');
    const formSubmitButton = document.getElementById('form-submit-button');
    const form = document.querySelector('.section-form__form');

    licenseCheckbox.onchange = (e) => {
      formSubmitButton.classList.toggle('disabled');
    }
  }
  

  function init() {
    licenseCheckListener();
    formValidate();
    onScroll();
  }

  init();
}